import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useHomeData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicHomePage {
          uid
          _previewable
          data {
            body {
              ... on PrismicHomePageBodyHero {
                id
                slice_type
                primary {
                  video {
                    url
                  }
                  title {
                    html
                  }
                  tagline {
                    text
                  }
                  scroll_down_text {
                    text
                  }
                  background_image {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                    alt
                  }
                }
              }
              ... on PrismicHomePageBodyHomeAwayFromHome {
                id
                slice_type
                primary {
                  title {
                    html
                  }
                  text {
                    text
                  }
                }
                items {
                  title {
                    text
                  }
                  text {
                    text
                  }
                  image {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              ... on PrismicHomePageBodyResort {
                id
                slice_type
                primary {
                  title {
                    html
                  }
                }
                items {
                  title {
                    text
                  }
                  text {
                    text
                  }
                  image {
                    fixed(width: 45) {
                      height
                      src
                      srcSet
                      width
                    }
                    alt
                  }
                }
              }
              ... on PrismicHomePageBodyGallery {
                id
                slice_type
                items {
                  title {
                    text
                    html
                  }
                  text {
                    text
                  }
                  image {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                    alt
                  }
                  link {
                    url
                    link_type
                  }
                  item_type
                  link_label
                }
              }
              ... on PrismicHomePageBodySlider {
                id
                slice_type
                primary {
                  link {
                    url
                    link_type
                  }
                  link_label
                  text {
                    text
                  }
                  title {
                    html
                  }
                }
                items {
                  image {
                    alt
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              ... on PrismicHomePageBodyNewsletter {
                id
                slice_type
                primary {
                  title {
                    html
                  }
                  text {
                    text
                  }
                  subscribe_text {
                    text
                  }
                  button_text
                }
              }
              ... on PrismicHomePageBodyBook {
                id
                slice_type
                primary {
                  text {
                    text
                  }
                  link {
                    url
                    link_type
                  }
                  button_text
                }
              }
            }
          }
          prismicId
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  return mergedData
}
