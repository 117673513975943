import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ResortItem = ({ image, title, text }) => {
  return (
    <Container>
      <ImageContainer>
        <Img fixed={image.fixed} />
      </ImageContainer>
      <Title>{title.text}</Title>
      <Text>{text.text}</Text>
    </Container>
  )
}

export default ResortItem

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  .gatsby-image-wrapper {
    max-width: 50px;
    //max-height: 50px;
    //height: 45px;
    width: 45px;
  }
`

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-template-rows: 56px auto auto;
  max-width: 185px;
  padding: 32px 16px;
`

const Title = styled.p`
  text-align: center;
  max-width: 180px;
  color: var(--primary-text);
  margin: 16px 0;
  font-family: "Moderat Bold", sans-serif;
`
const Text = styled.p`
  text-align: center;
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  padding: 0 16px;
`
