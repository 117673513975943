import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link } from "gatsby"
import Reveal from "react-awesome-reveal"

import { UserFeedbackMessage } from "../atoms/userFeedback"
import revealAnimation from "../../styles/animations/revealAnimation"

type mailChimpResponse = {
  result: string
  msg: string
}

const Newsletter = ({ title, text, subscribe_text, button_text }) => {
  const [email, setEmail] = useState<string>("")
  const [mailchimpResponse, setMailchimpResponse] =
    useState<mailChimpResponse | null>(null)

  const signUp = async () => {
    setMailchimpResponse(null)
    const response: mailChimpResponse = await addToMailchimp(email)

    if (response.result !== "success") {
      return setMailchimpResponse({
        ...response,
        msg: response.msg.slice(0, response.msg.indexOf("<")),
      })
    }
    setMailchimpResponse(response)
  }

  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  return (
    <Reveal keyframes={revealAnimation} triggerOnce>
      <Container>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text>{text.text}</Text>
        <SubscribeContainer>
          <Input
            aria-label="email address input"
            type="email"
            placeholder={subscribe_text.text}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            aria-label="Sign up button"
            onClick={signUp}
            disabled={!email.match(regex)}
          >
            {button_text}
          </Button>
        </SubscribeContainer>
        {mailchimpResponse ? (
          <UserFeedbackMessage type={mailchimpResponse.result as 'success' | 'error'}>
            {mailchimpResponse.msg}
          </UserFeedbackMessage>
        ) : null}
        <Disclaimer>
          By subscribing to the mailing list you agree with our{" "}
          <StyledLink to="/privacy-policy/">Privacy Policy</StyledLink>
        </Disclaimer>
      </Container>
    </Reveal>
  )
}

export default Newsletter

const Container = styled.div`
  padding: 104px 16px;
  display: grid;
  place-items: center;
  //max-width: 440px;
  margin: 0 auto 20px auto;
`

const Title = styled.div`
  max-width: 340px;
  h2 {
    color: var(--primary-text);
    font-size: 3.5rem;
    font-family: "Canela Light", sans-serif;
    text-align: center;
    margin: 2rem auto 2rem auto;
  }
`
const Text = styled.p`
  text-align: center;
  color: var(--primary-text);
`

const SubscribeContainer = styled.div`
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  justify-items: center;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    input {
      text-align: center;
    }
  }
`

const Input = styled.input`
  padding: 2px 8px;
  border: none;
  font-size: 16px;
  width: 300px;
  border-bottom: 1px solid var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  ::placeholder {
    color: var(--primary-text);
  }
`

const Button = styled.button`
  padding: 8px 32px;
  height: 40px;
  border: 1px solid var(--primary-text);
  max-width: 100px;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  background: var(--primary-white);
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  :hover {
    transition: all 0.3s ease-in-out;
    background: var(--primary-text);
    color: var(--primary-white);
  }
  &:disabled {
    background: var(--primary-text);
    color: white;
    border: 1px solid var(--primary-text);
    opacity: 0.5;
    cursor: initial;
  }
`

const Disclaimer = styled.p`
  padding-top: 40px;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  text-align: center;
`
const StyledLink = styled(Link)`
  color: var(--primary-blue);
`
